import React from 'react';
import "../styles/css/styles.css"

import Loading from "../components/shared/Loading"

const List = (props) => {

    console.log("🚀 ~ file: List.jsx:9 ~ List ~ props.datas :", props.datas )
    if(props.datas == undefined) return null

    return(
        props.load ?
            <Loading />
        :
            props.datas == "not-found" ?
                <h3>Nenhum resultado encontrado</h3>
            :
            <div className="dicas-container">
                {   
                        props.datas.map((item,index) => (
                            <div key={index} className="dicas-item">
                                <a href={`${props.path}/${item.slug}`} title={item.title.rendered}>
                                    {
                                        item?._embedded || item?.yoast_head_json?.og_image?.[0]?.url ?
                                            item?._embedded && item?._embedded["wp:featuredmedia"] ?
                                                <img src={item?._embedded["wp:featuredmedia"][0]?.source_url} alt={item.title.rendered} width="100%" height="auto" />
                                            : 
                                                item?.yoast_head_json && item?.yoast_head_json?.og_image[0].url && item?.yoast_head_json && item?.yoast_head_json?.og_image[0].url.indexOf("/wega.jpg") == -1 ?
                                                    
                                                    <img src={item?.yoast_head_json?.og_image[0].url} alt={item.title.rendered} width="100%" height="auto" />
                                                : null
                                        : null
                                    }
                                    <div>
                                        <span dangerouslySetInnerHTML={{__html: item.subtitle}} />
                                        <h3 dangerouslySetInnerHTML={{__html: item.title.rendered }} />
                                        <button>{ props.buttonLabel }</button>
                                    </div>
                                </a>
                            </div>
                        ))
                }
            </div>
    )
}

export default List;